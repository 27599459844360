import React from "react";
import Layout from "../components/layout"
import Map from "../components/map";
import HeaderEN from "../components/HeaderEN";
import FooterEN from "../components/FooterEN";
import MapSmall from "../components/map-small";

const EnglishPage = () => (
    <Layout>
        <HeaderEN/>
        <div className={"container-lg mt6 pt0-lg pt4"}>
            <div className={"r ac jc jc-lg"}>
                <div className={"c mwsm"}>
                    {/*<div className={"c tac my6 pb6"}>*/}
                    {/*<h1 className={"mb4 pb4"}><b>Career Fair Event Completed</b></h1>*/}
                    {/*<h3 className={"mb1"}><b>Check back for future event dates!</b></h3>*/}
                    {/*<p className={"mt4 select-map"}>For more employment information and to submit your application for open positions, please visit:</p>*/}
                    {/*<p className={"mb0"}>Roanoke Area Positions: <a target={"_blank"} rel={"noopener"} href={"https://jobs.branchgroup.com/gjhopkins"}>jobs.branchgroup.com/gjhopkins</a></p>*/}
                    {/*<p className={"mb0"}>Charlottesville Area Positions: <a target={"_blank"} rel={"noopener"} href={"https://jobs.branchgroup.com/lalacy"}>jobs.branchgroup.com/lalacy</a></p>*/}
                    <h2 className={"mb4"}><b>DESIGN YOUR FUTURE BUILD YOUR LEGACY</b></h2>
                    <p className={"mt4 select-map"}>Select your preferred region on the map.</p>
                    <p className={"note"}><span className={"c-gjh"}>*</span> <i>To interview at the Waynesboro location, please select Charlottesville on the map.</i></p>
                </div>
                <div className={"w100 w0-lg"}></div>


             </div>
            <Map/>
            <MapSmall/>
        </div>
        <FooterEN/>
    </Layout>
);

export default EnglishPage;